import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import { SENTRY_DSN, envInfo } from '@constants/constants';

import App from './App.tsx';

import './index.css';

const sentryDsn = envInfo.sentry_enabled ? SENTRY_DSN : null;
Sentry.init({
  dsn: sentryDsn,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost', envInfo.baseurl],
  environment: envInfo.keycloak_realm,
  sendDefaultPii: false,
  release: process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Router>
    <App />
  </Router>,
);
